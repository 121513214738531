import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import {countries, getCountryByCode } from 'Components/UI/Misc/countries'
import Form from 'Components/UI/Form'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'

class StoresPage extends PureComponent {
  constructor(props) {
    super(props)
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Stores'
      }
    ]
    this.form = React.createRef()
    this.allStores = this.props.data.allStoresCsv.edges
    const countriesWithStores = countries.filter(country => {
      for (let index = 0; index < this.allStores.length; index++) {
        const element = this.allStores[index].node
        if (element.country === country.value) return true
      }
      return false
    })
    this.countriesWithStores = countriesWithStores.map(item => {
      if (item.value === 'TW') return { ...item, label: 'Taiwan China' }
      else return item
    })
    this.state = {
      country: getCountryByCode('FR')
    }
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  filterCountries = () => {
    const value = this.form.current.refs.country.state.value.value
    this.setState({ country: getCountryByCode(value) })
  }
  render() {
    const form = [
      [
        {
          type: 'select',
          props: {
            id: 'country',
            className: 'u-offset-3 u-col-6 u-offset-md-4 u-col-md-4',
            placeholder: 'Select a country',
            options: this.countriesWithStores,
            value: this.state.country,
            required: false,
            onChange: this.filterCountries
          }
        }
      ]
    ]
    const stores = this.allStores
      .filter(item => item.node.country === this.state.country.value)
      .map((store, index) => {
        return (
          <div className={`u-text-center u-mrg-b--40 u-fit-w`} key={`${store.title}_${index}`}>
            <div className="u-inline-block">
              <h1 className={`t-title-1 u-uppercase u-mrg-b--10`}>{store.node.title}</h1>
              <p className={`t-paragraph-1`}>{`${store.node.address}, ${store.node.city}, ${getCountryByCode(store.node.country).label}`}</p>
              <p className={`t-paragraph-1`}>{`Telephone: ${store.node.telephone}`}</p>
              <p className={`t-paragraph-1`} dangerouslySetInnerHTML={{ __html: `Hours: ${store.node.opening_hours}` }}/>
            </div>
          </div>
        )
    })
    return (
      <Layout>
        <SeoHead
          title="Stores"
          path={this.props.location.pathname}
          description={`Stella Luna Boutiques`}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className='u-height--120' />
        <div className={`u-offset-md-2 u-offset-lg-3 u-col-md-8 u-col-lg-6 u-pad-x--md`}>
          <h1 className={`t-big-title u-uppercase u-text-center`}>Find a store</h1>
          <div className='u-height--20' />
          <Form items={form} submit={() => {}} ref={this.form}></Form>
          <div className='u-height--60' />
          <div className={`u-col-10 u-offset-1 u-text-center`}>{stores}</div>
        </div>
        <div className='u-height--120' />
      </Layout>
    )
  }
}

export const StoresQuery = graphql`
query StoresQuery {
  allStoresCsv {
    edges {
      node {
        title
        address
        city
        country
        telephone
        opening_hours
      }
    }
  }
}
`

export default StoresPage
